import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

      
    //{wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}
    //{wallet && <p>Total Available: {itemsAvailable}</p>}
    //{wallet && <p>Redeemed: {itemsRedeemed}</p>}

    //{wallet && (
    //<p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
    //)}
    //      {!isActive ? (<p>Mint Date: <b>21. October 19:30 UTC</b></p>) : (<p>Minting now!</p>)}
    
  return (
      <main>
	  <div style={{display: 'flex',  justifyContent:'center'}}>	
          <h1>Solana Dolls™</h1>
      </div>
	  <div style={{display: 'flex',  justifyContent:'center'}}>	
	  <h3>Practice your NFT Voodoo skills with Solana Dolls™</h3>
      </div>
      	  <div style={{display: 'flex',  justifyContent:'center'}}>	
	  <br/>
      	<a href="https://twitter.com/SolanaDollsNFT" target="_blank">
	    <img src="twitter.svg" style={{padding:"10px"}}/>
	</a>	
	<a href="https://discord.gg/g5CYu9RB6X" target="_blank>">
	    <img src="discord.svg" style={{padding:"10px"}}/>
	</a>
      </div>
	  <br/>
      Total Supply: <b>88</b>
	  <br/>
      {wallet && <p>Remaining: {itemsRemaining}</p>}
	  <br/>



      Fair mint (see technical details)<br/><br/>

	  Price: 0.8 SOL<br/><br/>

      <MintContainer>
        {!wallet ? (
          <ConnectButton>Connect Wallet</ConnectButton>
        ) : (
          <MintButton
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "MINT"
              )
            ) : (
              <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
        )}
      </MintContainer>
	  <br/>
	  <br/>

	  
	  <div>
	  <img src="solana_doll_sample01.png" alt="Solana Doll NFT" width="18%" style={{paddingRight: "10px", paddingLeft: "10px"}}/>
	  <img src="solana_doll_sample02.png" alt="Solana Doll NFT" width="18%" style={{paddingRight: "10px"}}/>
	  <img src="solana_doll_sample03.png" alt="Solana Doll NFT" width="18%" style={{paddingRight: "10px"}}/>
	  <img src="solana_doll_sample05.png" alt="Solana Doll NFT" width="18%" style={{paddingRight: "10px"}}/>
	  <img src="solana_doll_sample06.png" alt="Solana Doll NFT" width="18%" style={{paddingRight: "10px"}}/>
	  </div>

	  <h1>What is unique about Solana Dolls™?</h1>
	  <ul>
	  <li>Fully unique artwork created by an AI. Not just templates of parts randomly layered together on top of each other.</li>
	  </ul>

	  <h1>Rarity</h1>
	  <ul>
	  <li>The total supply of only 88 NFTs maket them rare by default.</li>
	  <li>Each Solana Doll™ is truly unique. Not a random assembly of parts but a unqiue artwork created by an AI.</li>
	  </ul>

      

	  <h1>FAQ</h1>
	  <ul>
	  <li><b>What is a stealth mint?</b><br/>
	  We have invited a small number of whitelisted people for this mint. If there is still something left and somebody finds this mint, try your luck. We keep things short here on purpose, as people already know and we have very limited suppply of 88 NFTs only. If you came here by accident, we still encourage you to do your due dilligence (see technical details).</li><br/>
	  <li><b>Where is your Discord Server / Twitter</b><br/>
      This is a Stealth Mint (see above) for people already in the know. We do not have a public discord server but only a private one that will be revealed to Solana Doll™ owners.</li><br/>
	  <li><b>How do I know this is legit?</b><br/>
	  We are glad you asked. As this is a stealth mint with a semi-anonymous team you might want to know what is going on here. Unfortunately with the popularity of Solana there were few black sheep including some scams going on. We want to be 100% tranparent with you.
	  See Technical Details on how to verify this yourself.</li><br/>
	  <li><b>Are those sample images photos?</b></li>
	  No. All Solana Dolls™ are fully AI generated artwork from the Metaverse.
	  </ul>


<h1>Technical Details</h1>
	  <ul>
	  <li><b>Arweaeve</b> - All image assets are stored on Arweave to stay there forever.<br/> See <a style={{color:"#ffffff"}} href="https://arweave.medium.com/nft-permanence-with-arweave-35b5d64eff23">"NFT Permanence with Arweave"</a></li><br/>
	  <li><b>Metaplex Candy Machine</b> - Fair mint via the <a style={{color:"#ffffff"}} href="https://metaplex.com/">Metaplex Candy Machine</a>. Reliable mint that just works.<br/><br/>
	  Our Candy Machine Program on Chain: <a style={{color:"#ffffff"}} href="https://solscan.io/account/EVELrHbjdvrKWYMpjwRcQmZ8ycCb2Djv6YoGojFSuhXv">EVELrHbjdvrKWYMpjwRcQmZ8ycCb2Djv6YoGojFSuhXv</a><br/><br/>
	  Depending on the wallet you choose, you can validate the on-chain program (the candy machine) your wallet interacts with. You can also validate the configuration with Solana Blockchain exploration tools (e.g. Solscan.io or Solana Explorer).
	  </li><br/>
	  
	  <li>Second Market Royalty is configured to 5% royalty for us. You keep 95%.</li>
	  </ul>



      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
	  </Snackbar>
	  <div style={{textAlign: "center"}}>Copyright 2021 by SolanaDolls.com</div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
